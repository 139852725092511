import React, { useMemo } from "react";

// import AnimalProfile from "layouts/gestionAnimaux/component/AnimalProfile";
import { useLocation } from "react-router-dom";
import BackButton from "components/BackButton";
import servicesAPI from "services/servicesAPI";
import LoadComponent from "components/LoadComponent";
import ModuleTitle from "components/ModuleTitle";
import MiseBasInfo from "components/MiseBasInfo";

function DetailMiseBas() {
  const { state } = useLocation();
  const { selectedData } = state;
  const {
    isAlert,
    isLoading,
    message,
    alertSeverity,
    handleLoad /* , getData, putData, postData */,
  } = servicesAPI();

  const loadDataConfig = useMemo(() => {
    const value = {
      isAlert,
      isLoading,
      message,
      alertSeverity,
      handleLoad,
    };
    return value;
  }, [isAlert, isLoading, message, alertSeverity]);
  /* const propsDataConfig = useMemo(() => {
    const value = {
      selectedData: selectedData.animal,
      ApiService: { getData, putData, postData },
    };
    return value;
  }, [selectedData]); */

  return (
    <>
      <ModuleTitle title="Informations de la mise bas" />
      <BackButton />
      <MiseBasInfo info={selectedData} />

      <LoadComponent loadData={loadDataConfig} />
    </>
  );
}

export default DetailMiseBas;
