// Custom hook to get permission from the local storage token

import userInfos from "./userInfos";

const permissionFetcher = (module) => {
  const permissions = [];
  try {
    const userData = userInfos();
    if (Object.keys(userData).length !== 0) {
      const roleData = userData.roles;
      roleData.map((i) => {
        return i.permissions.filter((x) => {
          const item = [];
          if (x.name.includes(module)) {
            permissions.push(x);
          }
          return item;
        });
      });
    }
  } catch (error) {
    console.error("getPermission error", error);
    throw error;
  }
  return permissions;
};
export default permissionFetcher;
