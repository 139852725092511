import { Collapse } from "@mui/material";
import PropTypes from "prop-types";
// import ExpandLess from "@material-ui/icons/ExpandLess";
// import ExpandMore from "@material-ui/icons/ExpandMore";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { NavLink, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import globalStyles from "style";
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import { LAST_PATH } from "constant";

function SidenavNested({ route }) {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  const handleClick = (value) => {
    setOpen(value);
  };
  // console.log("ggg", collapseName);
  /* return (
    <List>
      <ListItem button onClick={handleClick}>
        <ListItemText primary="Nested Routes" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {route.collapse.map((item) => (
          <NavLink key={item.key} to={item.route}>
            <SidenavCollapse name={item.name} icon={item.icon} active={active} />
          </NavLink>
        ))}
      </Collapse>
    </List>
  ); */
  useEffect(() => {
    if (!collapseName.includes(route.key)) {
      handleClick(false);
    }
  }, [collapseName]);
  const gobalClasses = globalStyles();
  return (
    <>
      <NavLink key={route.key} to={route.route} onClick={() => handleClick(true)}>
        <SidenavCollapse
          name={route.name}
          icon={route.icon}
          active={collapseName.includes(route.key)}
        >
          {open ? <ExpandLess /> : <ExpandMore />}
        </SidenavCollapse>
      </NavLink>
      <div>
        <Collapse in={open} timeout="auto" classes={gobalClasses.collapseMenu}>
          {route.collapse.map(
            (item) =>
              item.route !== route.route && (
                <NavLink
                  key={item.key}
                  to={item.route}
                  onClick={() => {
                    if (item.route !== "/logout") {
                      localStorage.setItem(LAST_PATH, item.route);
                      // console.log("gggg", item.route);
                    }
                  }}
                >
                  <SidenavCollapse
                    name={item.name}
                    icon={item.icon}
                    active={item.route.substring(1) === collapseName}
                    type="nested"
                  />
                </NavLink>
              )
          )}
        </Collapse>
      </div>
    </>
  );
}
SidenavNested.defaultProps = {
  // active: false,
};

// Typechecking props for the SidenavCollapse
SidenavNested.propTypes = {
  route: PropTypes.object.isRequired,
  // active: PropTypes.bool,
};

export default SidenavNested;
