import userInfos, { isAdminDepartemental } from "utils/userInfos";
import useArrayOperation from "hooks/useArrayOperation";
import { MODULE_LIEU } from "constant/moduleInfo";
import { ERROR } from "constant/message";

const { removeDuplicateItem } = useArrayOperation();
const getLieu = async (getData) => {
  const fetchData = await getData(MODULE_LIEU);
  let lieuClassified = {};
  if (fetchData !== ERROR) {
    const dataLieu = fetchData.filter((i) => i.codeRegion !== null);
    const canAddbyAdminDepartement = isAdminDepartemental();
    let relatedLieu = dataLieu;
    const userData = userInfos();
    if (canAddbyAdminDepartement) {
      relatedLieu = dataLieu.filter((i) => i.codeRegion === userData.lieu.codeRegion);
    }
    const listRegion = removeDuplicateItem(relatedLieu, "region");
    const listDepartement = removeDuplicateItem(relatedLieu, "departement");
    const listCommune = removeDuplicateItem(relatedLieu, "commune");
    lieuClassified = {
      listRegion,
      listDepartement,
      listCommune,
      relatedLieu,
    };
  }
  return lieuClassified;
};
export default getLieu;
