import React, { useMemo } from "react";
import { AnimalProvider } from "context/animalContext";
import AnimalProfile from "layouts/gestionAnimaux/component/AnimalProfile";
import { useLocation /* , useNavigate */ } from "react-router-dom";
import BackButton from "components/BackButton";
import servicesAPI from "services/servicesAPI";
import LoadComponent from "components/LoadComponent";
import ModuleTitle from "components/ModuleTitle";
import PropTypes from "prop-types";
import { Box, Typography, TextField } from "@mui/material";
import dateFormat from "utils/dateFormat";
import AnimalPerson from "layouts/gestionAnimaux/component/AnimalPerson";

function DetailConsultation() {
  // const navigate = useNavigate();
  const { state } = useLocation();
  const { selectedData } = state;
  const { isAlert, isLoading, message, alertSeverity, handleLoad, getData, putData, postData } =
    servicesAPI();

  const loadDataConfig = useMemo(() => {
    const value = {
      isAlert,
      isLoading,
      message,
      alertSeverity,
      handleLoad,
    };
    return value;
  }, [isAlert, isLoading, message, alertSeverity]);
  const propsDataConfig = useMemo(() => {
    const value = {
      selectedData: selectedData.animal,
      ApiService: { getData, putData, postData },
    };
    return value;
  }, [selectedData]);
  // console.log("dddd", selectedData);

  return (
    <>
      <ModuleTitle title="Informations de la consultation" />
      <BackButton />
      <RecapConsultation consultation={selectedData} />
      <AnimalProvider propsDataConfig={propsDataConfig}>
        <AnimalProfile />
      </AnimalProvider>
      <LoadComponent loadData={loadDataConfig} />
    </>
  );
}

export default DetailConsultation;
const RecapConsultation = ({ consultation }) => {
  let dateRappel = "";
  if (consultation?.delaiRappel === null) {
    dateRappel = "Non définie";
  } else {
    dateRappel = dateFormat(new Date(consultation?.delaiRappel));
  }
  let dateConsult = "";
  if (consultation?.dateConsult === null) {
    dateConsult = "Non définie";
  } else {
    dateConsult = dateFormat(new Date(consultation?.dateConsult));
  }
  // const responsableName = `${consultation?.user?.prenom ?? ""} ${consultation?.user?.nom ?? ""}`;
  const examinateurInfo = {
    type: "Examinateur",
    info: consultation.user,
  };
  return (
    <Box
      sx={{ padding: 2, border: "1px solid #ccc", borderRadius: 2, margin: 2 }}
      style={{ backgroundColor: "white" }}
    >
      <Box textAlign="center">
        <Typography variant="h6">Consultation</Typography>
      </Box>
      <TextField
        label="Date de la Consultation"
        name="date"
        value={dateConsult ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Date de Rappel"
        name="dateRappel"
        value={dateRappel ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Type de consultation"
        name="typeConsultation"
        value={consultation?.type ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Satut"
        name="status"
        value={consultation?.status ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Observation"
        name="observation"
        value={consultation?.observation ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      {/* <TextField
        label="Responsable"
        name="user"
        value={responsableName ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      /> */}
      <AnimalPerson person={examinateurInfo} />
    </Box>
  );
};
RecapConsultation.propTypes = {
  consultation: PropTypes.object.isRequired,
};
