// import { ERROR } from "constant/message";
import { MODULE_INSEMINATION } from "constant/moduleInfo";

export const createInsemination = async (postData, newData) => {
  console.log("dddd", MODULE_INSEMINATION);
  const fetchData = await postData(MODULE_INSEMINATION, newData);
  return fetchData;
};
export const updateInsemination = async (putData, newData) => {
  const fetchData = await putData(MODULE_INSEMINATION, newData);
  return fetchData;
};
// export default createDemandeIdentification;
