import React, { useMemo } from "react";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import { AnimalProvider } from "context/animalContext";
import { PATH_DETAIL_ANIMAL, PATH_DETAIL_INSEMINATION } from "constant/moduleInfo";
import AnimalDetails from "layouts/gestionAnimaux/component/AnimalDetails";
import FloatingSeeMoreButton from "components/FloatingSeeMoreButton";
import { useNavigate } from "react-router-dom";
import InseminationRecap from "components/InseminationInfo/InseminationRecap";
import MiseBasRecap from "./MiseBasRecap";

const MiseBasInfo = ({ info }) => {
  const navigate = useNavigate();

  const propsDataConfigGenitrice = useMemo(() => {
    const value = {
      selectedData: info?.animalGenetrice,
      title: "Génitrice",
      // ApiService: { getData, putData, postData },
    };
    return value;
  }, [info]);
  const propsDataConfigGeniteur = useMemo(() => {
    const value = {
      selectedData: info?.animalGeniteur,
      title: "Géniteur",
      // ApiService: { getData, putData, postData },
    };
    return value;
  }, [info]);
  // console.log("dddd", info);
  const animalParents = [
    {
      propsDataConfig: propsDataConfigGenitrice,
    },
    {
      propsDataConfig: propsDataConfigGeniteur,
    },
  ];
  const seeDetailParent = (parent) => {
    console.log("eeee", parent);
    navigate(PATH_DETAIL_ANIMAL, { state: { selectedData: parent } });
  };
  const seeDetailInsemination = (insemination) => {
    console.log("eeee", insemination);
    navigate(PATH_DETAIL_INSEMINATION, { state: { selectedData: insemination } });
  };
  return (
    <>
      <MiseBasRecap info={info} />
      {info?.type === "NORMAL" && (
        <Grid container spacing={2}>
          {animalParents.map((i) => (
            <Grid item xs={12} sm={6} md={6}>
              <AnimalProvider propsDataConfig={i.propsDataConfig}>
                <AnimalDetails />
                <FloatingSeeMoreButton
                  data={i.propsDataConfig.selectedData}
                  handleAction={seeDetailParent}
                />
              </AnimalProvider>
            </Grid>
          ))}
        </Grid>
      )}
      {info?.type === "INJECTION" && (
        <>
          <AnimalProvider propsDataConfig={propsDataConfigGenitrice}>
            <AnimalDetails />
            <FloatingSeeMoreButton
              data={propsDataConfigGenitrice.selectedData}
              handleAction={seeDetailParent}
            />
          </AnimalProvider>

          <InseminationRecap info={info?.insemination} />
          <FloatingSeeMoreButton data={info?.insemination} handleAction={seeDetailInsemination} />
        </>
      )}
    </>
  );
};

export default MiseBasInfo;
MiseBasInfo.propTypes = {
  info: PropTypes.object.isRequired,
};
