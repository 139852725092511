import { ERROR } from "constant/message";
import { MODULE_ESPECE } from "constant/moduleInfo";

import useArrayOperation from "hooks/useArrayOperation";
import {
  IMG_ESPECE_BOVIN,
  ESPECE_BOVIN,
  ESPECE_CAPRIN,
  IMG_ESPECE_CAPRIN,
  ESPECE_OVIN,
  IMG_ESPECE_OVIN,
  ESPECE_PORCIN,
  IMG_ESPECE_PORCIN,
  ESPECE_AULACODE,
  IMG_ESPECE_AULACODE,
  ESPECE_LAPIN,
  IMG_ESPECE_LAPIN,
  ESPECE_POULET_CHAIR,
  IMG_ESPECE_POULET_CHAIR,
  ESPECE_PONDEUSE,
  IMG_ESPECE_PONDEUSE,
  ESPECE_CHIEN,
  IMG_ESPECE_CHIEN,
  ESPECE_CHAT,
  IMG_ESPECE_CHAT,
  ESPECE_CHEVAL,
  IMG_ESPECE_CHEVAL,
} from "constant/espece";

const { sortArray } = useArrayOperation();
const getEspece = async (getData) => {
  const fetchData = await getData(MODULE_ESPECE);
  let result = [];
  if (fetchData !== ERROR) {
    const fullEspeceData = fetchData?.map((i) => {
      let img = "";
      if (i.espece?.nomCourant === ESPECE_BOVIN) {
        img = IMG_ESPECE_BOVIN;
      } else if (i.espece?.nomCourant === ESPECE_CAPRIN) {
        img = IMG_ESPECE_CAPRIN;
      } else if (i.espece?.nomCourant === ESPECE_OVIN) {
        img = IMG_ESPECE_OVIN;
      } else if (i.espece?.nomCourant === ESPECE_PORCIN) {
        img = IMG_ESPECE_PORCIN;
      } else if (i.espece?.nomCourant === ESPECE_AULACODE) {
        img = IMG_ESPECE_AULACODE;
      } else if (i.espece?.nomCourant === ESPECE_LAPIN) {
        img = IMG_ESPECE_LAPIN;
      } else if (i.espece?.nomCourant === ESPECE_POULET_CHAIR) {
        img = IMG_ESPECE_POULET_CHAIR;
      } else if (i.espece?.nomCourant === ESPECE_PONDEUSE) {
        img = IMG_ESPECE_PONDEUSE;
      } else if (i.espece?.nomCourant === ESPECE_CHIEN) {
        img = IMG_ESPECE_CHIEN;
      } else if (i.espece?.nomCourant === ESPECE_CHAT) {
        img = IMG_ESPECE_CHAT;
      } else if (i.espece?.nomCourant === ESPECE_CHEVAL) {
        img = IMG_ESPECE_CHEVAL;
      }
      const item = {
        ...i,
        img,
        // data: dataEspece?.counts ?? [],
      };
      return item;
    });
    result = sortArray(fullEspeceData, "nomCourant");
  }
  return result;
};
export default getEspece;
