export const SUPER_ADMIN_ROLE = "SUPERADMIN";
// export const ADMIN_ROLE = "ADMIN";
export const VETERINAIRE_ROLE = "Vétérinaire privé";
export const INSEMINATEUR_ROLE = "INSEMINATEUR";

export const ANIMAUX_COMPAGNIE_ROLE = "ANIMAUX_CAMPAGNIE";
export const ANIMAUX_ELEVAGE_ROLE = "ANIMAUX_ELEVAGE";
export const AGENT_ROLE = "AGENT_IDENTIFICATEUR";

export const SUPERVISEUR_ROLE = "SUPERVISEUR";
export const ADMIN_PAYS_ROLE = "ADMIN_PAYS";
export const ADMIN_DIRECTION_ROLE = "ADMIN_DIRECTION";
export const ADMIN_SOUS_SECTEUR_ROLE = "ADMIN_SOUS_SECTEUR";
export const ADMIN_REGION_ROLE = "ADMINISTRATEUR_REGIONAL";
// export const ADMIN_DEPARTEMENTAL_ROLE = "ADMINISTRATEUR_DEPARTEMENTAL";
export const ADMIN_DEPARTEMENTAL_ROLE = "ADMINISTRATEUR_DEPARTEMENTAL";
export const RESPONSABLE_FERME = "RESPONSABLE_FERME";
export const AGENT_SUPERVISEUR = "AGENT_SUPERVISEUR";
export const ELEVEUR = "ELEVEUR";
export const PROPRIETAIRE = "PROPRIETAIRE";
export const BERGER = "BERGER";

export const roleFonction = [
  { role: SUPER_ADMIN_ROLE, fonction: "Super Administrateur", niveau: 1 },
  { role: SUPERVISEUR_ROLE, fonction: "Superviseur", niveau: 1 },
  { role: ADMIN_PAYS_ROLE, fonction: "Administrateur Général", niveau: 1 },
  { role: ADMIN_DIRECTION_ROLE, fonction: "Administrateur Direction", niveau: 1 },
  //  { role: ADMIN_SOUS_SECTEUR_ROLE, fonction: "Administrateur Sous Secteur", niveau: 1 },
  { role: ADMIN_REGION_ROLE, fonction: "Administrateur Régional", niveau: 2 },
  { role: ADMIN_DEPARTEMENTAL_ROLE, fonction: "Administrateur Départemental", niveau: 3 },
  { role: AGENT_ROLE, fonction: "Agent Identificateur" },
  { role: ANIMAUX_COMPAGNIE_ROLE, fonction: "Animaux de compagnie" },
  { role: ANIMAUX_ELEVAGE_ROLE, fonction: "Animaux d'élevage" },
  { role: RESPONSABLE_FERME, fonction: "Responsable d'exploitation", niveau: 3 },
  { role: AGENT_SUPERVISEUR, fonction: "Agent Superviseur" },
  { role: ELEVEUR, fonction: "Eleveur" },
  { role: PROPRIETAIRE, fonction: "Propriétaire" },
  { role: BERGER, fonction: "Berger" },
];
export const roleN1 = [
  // { role: SUPER_ADMIN_ROLE, fonction: "Super Administrateur" },
  { role: SUPERVISEUR_ROLE, fonction: "Superviseur" },
  { role: ADMIN_PAYS_ROLE, fonction: "Administrateur Général" },
  // { role: ADMIN_DIRECTION_ROLE, fonction: "Administrateur Direction" },
  // { role: ADMIN_SOUS_SECTEUR_ROLE, fonction: "Administrateur Sous Secteur" },
  // { role: ANIMAUX_COMPAGNIE_ROLE, fonction: "Animaux de compagnie" },
  // { role: ANIMAUX_ELEVAGE_ROLE, fonction: "Animaux d'élevage" },
];

export const roleN2 = [{ role: ADMIN_REGION_ROLE, fonction: "Administrateur Régional" }];
export const roleAdminN3 = [
  { role: ADMIN_DEPARTEMENTAL_ROLE, fonction: "Administrateur Départemental" },
];
export const roleN4I = [
  { role: AGENT_ROLE, fonction: "Agent Identificateur" },
  { role: AGENT_SUPERVISEUR, fonction: "Agent Superviseur" },
];
export const roleN4R = [{ role: RESPONSABLE_FERME, fonction: "Responsable d'exploitation" }];
export const roleN5E = [{ role: ELEVEUR, fonction: "Eleveur" }];
export const roleN5P = [{ role: PROPRIETAIRE, fonction: "Propriétaire" }];

export const roleGestionnaires = [
  { role: ELEVEUR, fonction: "Eleveur" },
  { role: PROPRIETAIRE, fonction: "Propriétaire" },
  { role: BERGER, fonction: "Berger" },
];
