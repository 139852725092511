export const NO_PERMISSION =
  "Vous ne disposez pas des permissions necessaires pour effectuer cette action";
export const EMPTY_VALUE = "Veuillez saisir une valeur conforme";
export const SUCCESS = "success";
export const ERROR = "error";

export const NO_LIEU_FOUND = "Aucun lieu trouvé";
export const NO_ANIMAL_FOUND = "Aucun Animal trouvé";
export const NO_DATA_FOUND = "Aucune Donnée trouvée";
export const MODULE_NOT_AVAILABLE = "Module Non disponible";
