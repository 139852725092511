import React, { createContext, useMemo, useState } from "react";
import PropTypes from "prop-types";

export const AnimalContext = createContext();

export const AnimalProvider = ({ propsDataConfig, children }) => {
  const [animal, setAnimal] = useState(propsDataConfig.selectedData);

  const updateAnimalDetails = (updatedDetails) => {
    setAnimal((prevAnimal) => ({ ...prevAnimal, ...updatedDetails }));
  };

  const contextValue = useMemo(() => {
    const value = {
      animal,
      title: propsDataConfig.title,
      updateAnimalDetails,
      ApiService: propsDataConfig.ApiService,
    };
    return value;
  }, [animal]);

  return <AnimalContext.Provider value={contextValue}>{children}</AnimalContext.Provider>;
};

// Validation des props avec PropTypes
AnimalProvider.propTypes = {
  children: PropTypes.node.isRequired,
  propsDataConfig: PropTypes.object.isRequired,
};
