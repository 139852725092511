import React, { useMemo } from "react";

// import AnimalProfile from "layouts/gestionAnimaux/component/AnimalProfile";
import { useLocation } from "react-router-dom";
import BackButton from "components/BackButton";
import servicesAPI from "services/servicesAPI";
import LoadComponent from "components/LoadComponent";
import ModuleTitle from "components/ModuleTitle";
import MiseBasInfo from "components/MiseBasInfo";
import { Box, TextField, Typography } from "@mui/material";

function DetailProduit() {
  const { state } = useLocation();
  const { selectedData } = state;
  const {
    isAlert,
    isLoading,
    message,
    alertSeverity,
    handleLoad /* , getData, putData, postData */,
  } = servicesAPI();

  const loadDataConfig = useMemo(() => {
    const value = {
      isAlert,
      isLoading,
      message,
      alertSeverity,
      handleLoad,
    };
    return value;
  }, [isAlert, isLoading, message, alertSeverity]);
  // console.log("dddd", selectedData);
  return (
    <>
      <ModuleTitle title="Informations du produit" />
      <BackButton />
      <Box
        sx={{ padding: 2, border: "1px solid #ccc", borderRadius: 2, margin: 2 }}
        style={{ backgroundColor: "white" }}
      >
        <Typography variant="h6">Produit</Typography>
        <TextField
          label="Référence"
          name="refProduitInsemination"
          value={selectedData?.refProduitInsemination ?? "Non renseigné"}
          // onChange={handleInputChange}
          fullWidth
          margin="normal"
          disabled
        />
        <MiseBasInfo info={selectedData?.miseBas} />
      </Box>
      <LoadComponent loadData={loadDataConfig} />
    </>
  );
}

export default DetailProduit;
