import React from "react";
import PropTypes from "prop-types";
import dateFormat from "utils/dateFormat";
import { TextField, Box, Typography, Divider } from "@mui/material";

const MiseBasRecap = ({ info }) => {
  let dateMiseBas = "";
  if (info?.dateMiseBas === null) {
    dateMiseBas = "Non définie";
  } else {
    dateMiseBas = dateFormat(new Date(info?.dateMiseBas));
  }
  let type = "";
  if (info?.type === "NORMAL") {
    type = "Naturel";
  } else if (info?.type === "INJECTION") {
    type = "Artificiel";
  }
  return (
    <Box
      sx={{ padding: 2, border: "1px solid #ccc", borderRadius: 2, margin: 2 }}
      style={{ backgroundColor: "white" }}
    >
      <Typography variant="h6">Mise Bas</Typography>
      <TextField
        label="Référence"
        name="refMiseBas"
        value={info?.refMiseBas ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Type de croisement"
        name="type"
        value={type ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Date Mise Bas"
        name="dateMiseBas"
        value={dateMiseBas ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Ferme"
        name="ferme"
        value={info?.ferme?.nom ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Observation"
        name="observation"
        value={info?.observation ?? "Non renseigné"}
        fullWidth
        margin="normal"
        disabled
      />

      <Divider textAlign="center">
        <Typography variant="h6">Lieu</Typography>
      </Divider>
      <TextField
        label="Région"
        name="region"
        value={info?.lieu?.region ?? "Non renseigné"}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Département"
        name="departement"
        value={info?.lieu?.departement ?? "Non renseigné"}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Commune"
        name="commune"
        value={info?.lieu?.commune ?? "Non renseigné"}
        fullWidth
        margin="normal"
        disabled
      />
      {/* <AnimalPerson person={examinateurInfo} /> */}
    </Box>
  );
};

MiseBasRecap.propTypes = {
  info: PropTypes.object.isRequired,
};
export default MiseBasRecap;
