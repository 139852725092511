import { ERROR } from "constant/message";
import { MODULE_ROLE } from "constant/moduleInfo";
import { ADMIN_SOUS_SECTEUR_ROLE, SUPER_ADMIN_ROLE, roleFonction } from "constant/role";

import useArrayOperation from "hooks/useArrayOperation";

const { sortArray } = useArrayOperation();
const getRole = async (getData) => {
  const fetchData = await getData(MODULE_ROLE);
  let result = [];
  if (fetchData !== ERROR) {
    const filterData = fetchData.filter(
      (i) => i.roleName !== SUPER_ADMIN_ROLE && i.roleName !== ADMIN_SOUS_SECTEUR_ROLE
    );

    const roleFonctionArr = filterData.map((x) => {
      const fonctionData = roleFonction.find((y) => y.role === x.roleName);
      const item = {
        ...x,
        ...{ fonction: fonctionData?.fonction ?? x.roleName },
      };
      return item;
    });
    result = sortArray(roleFonctionArr, "fonction");
  }
  return result;
};
export default getRole;
