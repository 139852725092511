const listProfil = [
  { id: 1, label: "Ministre" },
  { id: 2, label: "Directeur de Cabinet" },
  { id: 3, label: "Directeur de Cabinet Adjoint" },
  { id: 4, label: "Conseiller Technique" },
  { id: 5, label: "Directeur de la Production Animal" },
  { id: 6, label: "Directeur des Services Vétérinaire" },
  // { id: 7, label: "Responsable BNIA" },
  // { id: 8, label: "Coordonnateur PRO-BIEN Être" },
  // { id: 9, label: "Sous-directrice de l'identification" },
  { id: 10, label: "Directeur Régional" },
  { id: 11, label: "Directeur Départemental" },
  /* { id: 12, label: "Coordonnateur PRO-BOVINE" },
  { id: 13, label: "Coordonnateur PRO-LAIT" },
  { id: 14, label: "Coordonnateur PRO-RACE" },
  { id: 15, label: "Coordonnateur PRO-PORC" },
  { id: 16, label: "Coordonnateur PRO-INTEGRE" },
  { id: 17, label: "Coordonnateur PRO-ALIMENT" }, */
  { id: 18, label: "Vétérinaires privés" },
  { id: 19, label: "Vétérinaires publics" },
  // { id: 20, label: "Administratrice" },
];
export const listProfilN5 = [{ id: 18, label: "Exploitant" }];
export const listProfilN4R = [{ id: 18, label: "Directeur Exploitation" }];
export const listProfilN4I = [
  { id: 19, label: "Vétérinaire public" },
  { id: 18, label: "Vétérinaire privé" },
  { id: 18, label: "Service Technique Décentralisé" },
  { id: 18, label: "Prestaire" },
];
export const listProfilN3 = [{ id: 11, label: "Directeur Départemental" }];
export const listProfilN2 = [{ id: 10, label: "Directeur Régional" }];
export const listProfilN1 = [
  { id: 1, label: "Ministre" },
  { id: 2, label: "Directeur de Cabinet" },
  { id: 3, label: "Directeur de Cabinet Adjoint" },
  { id: 4, label: "Conseiller Technique" },
  { id: 5, label: "Directeur de la Production Animal" },
  { id: 6, label: "Directeur des Services Vétérinaire" },
  /* { id: 8, label: "Coordonnateur PRO-BIEN Être" },
  { id: 9, label: "Sous-directrice de l'identification" },
  { id: 12, label: "Coordonnateur PRO-BOVINE" },
  { id: 13, label: "Coordonnateur PRO-LAIT" },
  { id: 14, label: "Coordonnateur PRO-RACE" },
  { id: 15, label: "Coordonnateur PRO-PORC" },
  { id: 16, label: "Coordonnateur PRO-INTEGRE" },
  { id: 17, label: "Coordonnateur PRO-ALIMENT" }, */
];
export default listProfil;
