import React, { useMemo } from "react";
import { AnimalProvider } from "context/animalContext";
import AnimalProfile from "layouts/gestionAnimaux/component/AnimalProfile";
import { useLocation /* , useNavigate */ } from "react-router-dom";
import BackButton from "components/BackButton";
import servicesAPI from "services/servicesAPI";
import LoadComponent from "components/LoadComponent";
import ModuleTitle from "components/ModuleTitle";
import InseminationInfo from "components/InseminationInfo";
import SemenceInfo from "components/SemenceInfo";
import AnimalPerson from "layouts/gestionAnimaux/component/AnimalPerson";

function DetailInsemination() {
  // const navigate = useNavigate();
  const { state } = useLocation();
  const { selectedData } = state;
  const { isAlert, isLoading, message, alertSeverity, handleLoad, getData, putData, postData } =
    servicesAPI();

  const loadDataConfig = useMemo(() => {
    const value = {
      isAlert,
      isLoading,
      message,
      alertSeverity,
      handleLoad,
    };
    return value;
  }, [isAlert, isLoading, message, alertSeverity]);
  const propsDataConfig = useMemo(() => {
    const value = {
      selectedData: selectedData.animal,
      ApiService: { getData, putData, postData },
    };
    return value;
  }, [selectedData]);
  // console.log("dddd", selectedData);
  const examinateurInfo = {
    type: "Inséminateur",
    info: selectedData?.inseminateur,
  };
  return (
    <>
      <ModuleTitle title="Informations de l'insemination" />
      <BackButton />
      <InseminationInfo info={selectedData} putData={putData} handleLoad={handleLoad} />
      <SemenceInfo info={selectedData?.lotSemence} />
      <AnimalPerson person={examinateurInfo} />
      <AnimalProvider propsDataConfig={propsDataConfig}>
        <AnimalProfile />
      </AnimalProvider>
      <LoadComponent loadData={loadDataConfig} />
    </>
  );
}

export default DetailInsemination;
/* const RecapInsemination = ({ selectedData }) => {
  let dateInjection = "";
  if (selectedData?.dateInjection === null) {
    dateInjection = "Non définie";
  } else {
    dateInjection = dateFormat(new Date(selectedData?.dateInjection));
  }
  let dateRetraitSpiral = "";
  if (selectedData?.dateRetraitSpiral === null) {
    dateRetraitSpiral = "Non définie";
  } else {
    dateRetraitSpiral = dateFormat(new Date(selectedData?.dateRetraitSpiral));
  }
  let postSpiral = "";
  if (selectedData?.postSpiral === null) {
    postSpiral = "Non définie";
  } else {
    postSpiral = dateFormat(new Date(selectedData?.postSpiral));
  }
  // const responsableName = `${consultation?.user?.prenom ?? ""} ${consultation?.user?.nom ?? ""}`;
  const examinateurInfo = {
    type: "Inséminateur",
    info: selectedData?.inseminateur,
  };
  return (
    <Box
      sx={{ padding: 2, border: "1px solid #ccc", borderRadius: 2, margin: 2 }}
      style={{ backgroundColor: "white" }}
    >
      <Typography variant="h6">Insémination</Typography>
      <TextField
        label="Date Injection"
        name="dateInjection"
        value={dateInjection ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Date Pose Spiral"
        name="postSpiral"
        value={postSpiral ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Date Retrait Spiral"
        name="dateRetraitSpiral"
        value={dateRetraitSpiral ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Statut"
        name="observation"
        value={selectedData?.observation ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <SemenceInfo info={selectedData?.lotSemence} />
      <AnimalPerson person={examinateurInfo} />
    </Box>
  );
};
RecapInsemination.propTypes = {
  selectedData: PropTypes.object.isRequired,
}; */
