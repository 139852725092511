export const USER_DATA_STORAGE = "userDataBC-MR";
export const LAST_PATH = "lastPathBC-MR";
export const MONTH_NAMES = [
  "Janv",
  "Fev",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juilet",
  "Aout",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const CURRENT_PAYS = "Mauritanie";
export const CODE_PAYS = "478";
export const PHONE_NUMBER_LENGTH = 8;
