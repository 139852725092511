/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "examples/Sidenav/styles/sidenavCollapse";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { makeStyles } from "@mui/styles";
import { List } from "@mui/material";

const useStyles = makeStyles(() => ({
  listItem: {
    overflow: "hidden",
    /* "&:hover": {
      backgroundColor: theme.palette.action.hover,
      // backgroundColor: "red",
      // Define other styles for hover state
    }, */
    // backgroundColor: "#f5f5f5",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      // Define other styles for hover state
    },
    // backgroundColor: "#f5f5f5",
    "&:selected ": {
      backgroundColor: "red",
      // Define other styles for hover state
    },
    marginLeft: 15,
  },
  listText: {
    color: "black",
    paddingRight: 15,
  },
}));
function SidenavCollapse({ icon, name, active, type, ...rest }) {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const classes = useStyles();
  return (
    <ListItem component="li">
      {type === "nested" ? (
        <div
          style={{
            // backgroundColor: "white",
            backgroundColor: `${active ? "#e0e0e0" : "white"}`,
            marginLeft: 30,
            marginRight: 20,
            width: "100%",
          }}
        >
          {/* <Demo> */}
          <List>
            <ListItem className={classes.listItem}>
              <ListItemText
                primary={name}
                className={classes.listText}
                // primaryTypographyProps={{ fontSize: "15px" }}
                primaryTypographyProps={{ fontSize: "14px", color: "black" }}
                sx={{
                  whiteSpace: "normal", // Permet au texte de revenir à la ligne
                  wordBreak: "break-word",

                  // maxWidth: "200px", // Ajuste selon les besoins
                  // cursor: isOverflowed ? "pointer" : "default", // Ajoute le style de curseur
                }}
              />
            </ListItem>
          </List>
          {/* </Demo> */}
        </div>
      ) : (
        <MDBox
          {...rest}
          sx={(theme) =>
            collapseItem(theme, {
              active,
              transparentSidenav,
              whiteSidenav,
              darkMode,
              sidenavColor,
            })
          }
        >
          <ListItemIcon
            sx={(theme) =>
              collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
            }
          >
            {typeof icon === "string" ? (
              <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
            ) : (
              icon
            )}
          </ListItemIcon>

          <ListItemText
            primary={name}
            sx={(theme) =>
              collapseText(theme, {
                miniSidenav,
                transparentSidenav,
                whiteSidenav,
                active,
              })
            }
          />
        </MDBox>
      )}
    </ListItem>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
  type: "",
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
  type: PropTypes.string,
};

export default SidenavCollapse;
