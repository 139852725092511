import { makeStyles } from "@mui/styles";

const globalStyles = makeStyles({
  centerContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: "red",
  },
  collapseMenu: {
    marginLeft: 20,
  },
});
export default globalStyles;
