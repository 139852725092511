import React from "react";
import { TextField, Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

const SemenceInfo = ({ info }) => {
  return (
    <Box
      sx={{ padding: 2, border: "1px solid #ccc", borderRadius: 2, margin: 2 }}
      style={{ backgroundColor: "white" }}
    >
      <Box textAlign="center">
        <Typography variant="h6">Semence</Typography>
      </Box>
      <TextField
        label="Dose Semence"
        name="idDoseSemence"
        value={info?.idDoseSemence ?? "Non renseigné"}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Nom Taureau"
        name="nomToureau"
        value={info?.nomToureau ?? "Non renseigné"}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Origine"
        name="originPays"
        value={info?.originPays ?? "Non renseigné"}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Race"
        name="raceToureau"
        value={info?.raceToureau ?? "Non renseigné"}
        fullWidth
        margin="normal"
        disabled
      />
    </Box>
  );
};

export default SemenceInfo;
SemenceInfo.propTypes = {
  info: PropTypes.object.isRequired,
};
