import useArrayOperation from "hooks/useArrayOperation";
import { ERROR } from "constant/message";
import { MODULE_USER } from "constant/moduleInfo";

const { customArrItem, sortArray } = useArrayOperation();

const getUserbyRole = async (getDataUser, listTargetRole) => {
  const dataByRole = await Promise.all(
    listTargetRole.map(async (i) => {
      const arrayData = await getDataUser(`Users/byRole/${i.role}`);
      return arrayData;
    })
  );
  const fetchData = dataByRole.flat();
  let dataUser = [];
  if (fetchData !== ERROR && fetchData?.some((i) => i !== ERROR)) {
    if (Array.isArray(fetchData) && fetchData.length > 0) {
      const customArr = customArrItem(fetchData, MODULE_USER);
      dataUser = sortArray(customArr, "userName");
    }
  }
  return dataUser;
};
export default getUserbyRole;
export const searchUser = async (postData, numTel) => {
  const searchValue = [
    {
      field: "numTel",
      searchTerm: numTel,
      queryType: "Text",
    },
  ];
  const fetchData = await postData("search/index/users", searchValue);
  let result;
  if (fetchData !== ERROR) {
    result = fetchData;
  }
  return result;
};
