import { useEffect /* , lazy, Suspense */ } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import SidenavNested from "examples/Sidenav/SideNavNested";
import { LAST_PATH, USER_DATA_STORAGE } from "constant";

/* const MDBox = lazy(() => import("components/MDBox"));
const MDTypography = lazy(() => import("components/MDTypography"));
const SidenavCollapse = lazy(() => import("examples/Sidenav/SidenavCollapse"));
const SidenavRoot = lazy(() => import("examples/Sidenav/SidenavRoot"));
// const sidenavLogoLabel = lazy(() => import("examples/Sidenav/styles/sidenav"));
const SidenavNested = lazy(() => import("examples/Sidenav/SideNavNested")); */

// function Sidenav({ color, brand, brandName, routes, userRoles, ...rest }) {
function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode /* , sidenavColor */ } =
    controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  // const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route, role }) => {
  const userToken = localStorage.getItem(USER_DATA_STORAGE);
  let userRoles = [];
  if (userToken !== null) {
    userRoles = JSON.parse(userToken).roles;
  }
  /* const renderRoutes = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route, role }) => { */
  const renderRoutes = routes.map((item) => {
    let returnValue;
    // console.log("ss", name, role, userRoles);
    const isAvalaible = item.role
      .map((el1) => ({
        name: el1,
        match: userRoles.some((el2) => el2.roleName.toLowerCase() === el1.toLowerCase()),
      }))
      .some((x) => x.match === true);
    // const isAvalaible = check_role
    // console.log(isAvalaible);
    if (item.type === "collapse" && isAvalaible) {
      returnValue = item.href ? (
        <Link
          href={item.href}
          key={item.key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            name={item.name}
            icon={item.icon}
            active={item.key === collapseName}
            noCollapse={item.noCollapse}
          />
        </Link>
      ) : (
        <NavLink
          key={item.key}
          to={item.route}
          onClick={() => {
            if (item.route !== "/logout") {
              localStorage.setItem(LAST_PATH, item.route);
            }
          }}
        >
          {item.collapse.length === 0 ? (
            <SidenavCollapse name={item.name} icon={item.icon} active={item.key === collapseName} />
          ) : (
            <SidenavNested route={item} active={item.key === collapseName} />
          )}
        </NavLink>
      );
    } else if (item.type === "title") {
      returnValue = (
        <MDTypography
          key={item.key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {item.title}
        </MDTypography>
      );
    } else if (item.type === "divider") {
      returnValue = (
        <Divider
          key={item.key}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }

    return returnValue;
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      {/* <Suspense fallback={<div>...</div>}> */}
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox /* to="/" */ display="flex" alignItems="center">
          {brand && <MDBox component="img" src={brand} alt="Brand" width="100%" />}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      {/* </Suspense> */}
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  // userRoles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
