import React from "react";
import Fab from "@mui/material/Fab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"; // Icon for 'See More'
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";

function FloatingSeeMoreButton({ handleAction, data }) {
  const handleClick = () => {
    console.log("See More clicked");
    // Add your functionality here
    handleAction(data);
  };

  return (
    <div style={{ position: "relative", bottom: 20, right: 0 }}>
      <Tooltip title="Voir plus" arrow>
        <Fab color="primary" aria-label="see more" onClick={handleClick}>
          <ExpandMoreIcon />
        </Fab>
      </Tooltip>
    </div>
  );
}

export default FloatingSeeMoreButton;
FloatingSeeMoreButton.propTypes = {
  handleAction: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};
