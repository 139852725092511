import React from "react";
import PropTypes from "prop-types";
import dateFormat from "utils/dateFormat";
import { TextField, Box, Typography } from "@mui/material";

const InseminationRecap = ({ info }) => {
  let dateInjection = "";
  if (info?.dateInjection === null) {
    dateInjection = "Non définie";
  } else {
    dateInjection = dateFormat(new Date(info?.dateInjection));
  }
  let dateRetraitSpiral = "";
  if (info?.dateRetraitSpiral === null) {
    dateRetraitSpiral = "Non définie";
  } else {
    dateRetraitSpiral = dateFormat(new Date(info?.dateRetraitSpiral));
  }
  let postSpiral = "";
  if (info?.postSpiral === null) {
    postSpiral = "Non définie";
  } else {
    postSpiral = dateFormat(new Date(info?.postSpiral));
  }

  return (
    <Box
      sx={{ padding: 2, border: "1px solid #ccc", borderRadius: 2, margin: 2 }}
      style={{ backgroundColor: "white" }}
    >
      <Box textAlign="center">
        <Typography variant="h6">Insémination</Typography>
      </Box>
      <TextField
        label="Date Injection"
        name="dateInjection"
        value={dateInjection ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Date Pose Spiral"
        name="postSpiral"
        value={postSpiral ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Date Retrait Spiral"
        name="dateRetraitSpiral"
        value={dateRetraitSpiral ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Dose Semence"
        name="dateRetraitSpiral"
        value={info?.lotSemence?.idDoseSemence ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
    </Box>
  );
};

InseminationRecap.propTypes = {
  info: PropTypes.object.isRequired,
};
export default InseminationRecap;
